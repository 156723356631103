//App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/web/Header/Header';
import Footer from './components/web/Footer/footer';
import HomePage from './components/web/HomePage/HomePage'; // A new component for the home page
import News from './components/web/News/NewsPage'; // A new component for the second page
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivacyPolicy from './components/web/Privacy/privacy';
// import Dashboard from './components/Dashboard/Dashboard';
import AboutusPage from './components/web/Aboutus/AboutusPage';
import AI4GoodPage from './components/web/AI4Good/AI4GoodPage';
import NewsEdit from './components/web/News/News_edit';
import CookieConsent from './components/web/CookieConsent/CookieConsent';
import TermsOfUse from './components/web/TermsOfUse/TermsOfUse';
import SvgMapComponent from './components/web/GBA/svgmap';

import ResourcesPage from './components/web/Resources/ResourcesPage';
import ToolsPage from './components/web/Tools/ToolsPage';

import { I18nextProvider } from 'react-i18next';
// import i18n from './i18n'; // We'll create this file next
import i18n from '../src/components/web/i18n/i18n'; // We'll create this file next

import NotFound from './components/Others/NotFound';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Helmet>
          <title>GREATERBAYAREA.AI - To leverage A.I. for the improvement of people's lives and the benefit of society.</title>
          <meta name="description" content="To leverage A.I. for the improvement of people's lives and the benefit of society." />
          <meta name="keywords" content="Artificial Intelligence, AI, Society, improvement, people's lives, Ethical AI, GREATER BAY AREA AI, benefit of society" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="canonical" href="https://greaterbayarea.ai/" />
          {/* Security headers */}
          <meta http-equiv="Content-Security-Policy" content="default-src 'self'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; style-src 'self' 'unsafe-inline';" />
          <meta http-equiv="X-Frame-Options" content="DENY" />
          {/* Add additional meta tags, OpenGraph tags, and structured data here */}
          {/* Consider adding HTTP Strict Transport Security (HSTS) via headers for HTTPS enforcement */}
        </Helmet>
        <CookieConsent />
        {/* <Route path="/AI4Good" element={<AI4GoodPage />} /> */}
        <div className="App">
          <Header />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/news" element={<News key={i18n.language} />} />
            <Route path="/newsedit" element={<NewsEdit />} />
            <Route path="/aboutus" element={<AboutusPage />} />

            <Route path="/AI4Good" element={<AI4GoodPage key={i18n.language} />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy  key={i18n.language}/>} />
            <Route path="/TermsOfUse" element={<TermsOfUse />} />
            <Route path="/svgmap" element={<SvgMapComponent width={1160} height={740} />} />

            <Route path="/resources" element={<ResourcesPage />} />
            <Route path="/Tools" element={<ToolsPage />} />
            <Route component={NotFound} />

            {/* <Route path="/dashboard" element={<Dashboard />} /> */}


          </Routes>
          <Footer key={i18n.language} />
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;